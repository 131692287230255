import React, { createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { createClient } from '@supabase/supabase-js';

import axios from 'axios';

import '../../../styles/App.scss';
import '../../../styles/backend/blondedcms.scss';

class BlondedcmsHerincasso extends React.Component {

    constructor(props) {
        super(props);
        this.tableRef = createRef();
    }

    state = {
        //GET ACCOUNT INFO
        user: {},
        
        //GET VONNISBANK HERINCASSO
        allHerincasso: []
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user || user.role !== 'vonnisbank_admin') {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({ user: user });
                    this.getAllHerincasso();
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getAllHerincasso() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/admin/herincasso/details`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ allHerincasso: response.data.herincasso });
        })
        .catch(error => {
            console.log(error);
        });
    }

    deleteHerincasso(e) {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        var closestHerincasso = e.target.closest('.herincasso');

        var herincassoId = closestHerincasso.getAttribute('id');

        var herincassoVonnisUrl = closestHerincasso.querySelector('.vonnis').getAttribute('href').replace(process.env.REACT_APP_SUPABASE_STORAGE_URL, '');
        var herincassoBetekeningUrl = closestHerincasso.querySelector('.betekening').getAttribute('href').replace(process.env.REACT_APP_SUPABASE_STORAGE_URL, '');

        const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
        const supabaseKey = process.env.REACT_APP_SUPABASE_SERVICE_ROLE;
        const supabase = createClient(supabaseUrl, supabaseKey);

        supabase
        .storage
        .from('vonnisbank-files')
        .remove([herincassoVonnisUrl, herincassoBetekeningUrl]);

        axios.get(`${baseUrl}/api/admin/herincasso/delete/` + herincassoId,  {
            withCredentials: true,
        })
        .then(response => { 
            window.location.reload(false);
        })
        .catch(error => {
            console.log(error);
        });
    }

    buildHerincassoList(herincassoData) {
        if (herincassoData.length > 0) {
            return herincassoData.map((herincassoData, index) => (
                <tr key={index} className="herincasso visible" id={herincassoData._id}>
                  <td>{herincassoData.createdBy}</td>
                  <td className="coc-selector">{herincassoData.cocNumber}</td>
                  <td><a className='vonnis text-ellipsis overflow-hidden block w-36 text-sky-500 underline hover:text-sky-900' href={herincassoData.vonnisPdf} target='_blank' rel='noreferrer'>{herincassoData.vonnisPdf}</a></td>
                  <td><a className='betekening text-ellipsis overflow-hidden block w-36 text-sky-500 underline hover:text-sky-900' href={herincassoData.betekening} target='_blank' rel='noreferrer'>{herincassoData.betekening}</a></td>
                  <td><button onClick={this.deleteHerincasso.bind(this)} className="button btn-small bg-gray-900 hover:bg-white text-white hover:text-gray-900">Herincasso verwijderen</button></td>
                </tr>
            ));
        }
    }
    
    render() {
        return (
            <div className="Main">
                 <div className="blondedcms">
                    <div className="flex flex-col items-center w-16 overflow-hidden blonded-sidebar">
                        <div className="flex items-center justify-center">
                            <svg className="sidebar-icon w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                            </svg>
                        </div>
                        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
                            <a className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-900 hover:text-white" href="/blondedcms/admin">
                                <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                            </a>
                        </div>
                        
                        <a className="flex items-center justify-center w-16 h-16 mt-auto hover:bg-gray-900 hover:text-white" href="/account">
                            <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </a>
                    </div>
                    <div className="blonded-content">
                        <div className="cms-wrapper">
                            <div className="cms-header">
                                <h1 className="cms-title">Blonded CMS</h1>
                                <p className="cms-subtitle">Welkom {this.state.user.name}</p>
                            </div>
                            <div className="cms-body">
                                <h2 className="body-title">Vonnisbank herincassos</h2>
                                <p className="body-subtitle">Volledige lijst met geregistreerde herincassos.</p>

                                <div className="flex flex-row flex-wrap">
                                    <DownloadTableExcel
                                            filename="vonnis_table"
                                            sheet="vonnissen"
                                            currentTableRef={this.tableRef.current}
                                        >
                                            <button className="button btn-cms-primary">Exporteer herincasso lijst</button>
                                    </DownloadTableExcel>
                                </div>

                                <div className="flex flex-row flex-wrap scroll-table-row">
                                    <div className="basis-full">
                                        <div className="table-responsive vonnis-table admin-responsive">
                                            <table className="data-table admin-table" ref={this.tableRef}>
                                                <thead>
                                                    <tr>
                                                        <th>Aangemaakt door</th>
                                                        <th>KvK-nummer</th>
                                                        <th>Vonnis</th>
                                                        <th>Betekening</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.buildHerincassoList(this.state.allHerincasso)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <BlondedcmsHerincasso navigate={navigate} />
}

export default WithNavigate;