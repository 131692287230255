import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import Overons from './pages/content/Overons';
import Overzichtkosten from './pages/content/OverzichtKosten';
import Hoehetwerkt from './pages/content/Hoehetwerkt';
import Privacy from './pages/content/Privacy';
import Voorwaarden from './pages/content/Voorwaarden';
import Disclaimer from './pages/content/Disclaimer';
import Contact from './pages/content/Contact';
import ContactSent from './pages/content/ContactVerzonden';
import Faq from './pages/content/Faq';

import Login from './pages/backend/Login';
import ForgotPassword from './pages/backend/ForgotPassword';
import ForgotPasswordMail from './pages/backend/ForgotPasswordMail';
import ForgotPasswordChange from './pages/backend/ForgotPasswordChange';
import LinkExpired from './pages/backend/LinkExpired';
import PageNotFound from './pages/backend/PageNotFound';
import Register from './pages/backend/Register';
import Activate from './pages/backend/Activate';
import AccountActivated from './pages/backend/AccountActivated';
import RegisterDone from './pages/backend/RegisterDone';
import Account from './pages/backend/Account';
import AccountEdit from './pages/backend/AccountEdit';
import Colleagues from './pages/backend/Colleagues';
import VonnisMine from './pages/backend/VonnisMine';
import VonnisCreate from './pages/backend/VonnisCreate';
import VonnisEdit from './pages/backend/VonnisEdit';
import VonnisSearch from './pages/backend/VonnisSearch';
import VonnisCreated from './pages/backend/VonnisCreated';
import HerIncasso from './pages/backend/Herincasso';
import HerincassoCreated from './pages/backend/HerincassoCreated';
import Incasso from './pages/backend/IncassoCreate';
import IncassoCreated from './pages/backend/IncassoCreated';
import Factuur from './pages/backend/FactuurCreate';
import FactuurCreated from './pages/backend/FactuurCreated';
import PrevonnisbankMine from './pages/backend/prevonnisbankMine';
import PrevonnisbankVoorwaarden from './pages/content/Voorwaarden-prevonnisbank';
import PrevonnisbankUitleg from './pages/content/uitleg-prevonnisbank';
import Rapport from './pages/backend/Rapport';
import RapportCreated from './pages/backend/RapportCreated';
import FreeCta from './pages/content/FreeCta';
import Blondedcms from './pages/backend/admin/Admin';
import BlondedcmsUsers from './pages/backend/admin/Users';
import BlondedcmsVonnis from './pages/backend/admin/Vonnis';
import BlondedcmsHerincasso from './pages/backend/admin/Herincasso';
import BlondedcmsIncasso from './pages/backend/admin/Incasso';
import BlondedcmsRapport from './pages/backend/admin/Rapport';
import BlondedcmsPrevonnisbank from './pages/backend/admin/Prevonnisbank';
import BlondedcmsCompanies from './pages/backend/admin/Companies';
import BlondedcmsTools from './pages/backend/admin/Tools';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/" element={<App />} />
      <Route path="/over-ons" element={<Overons />} />
      <Route path="/kosten" element={<Overzichtkosten />} />
      <Route path="/hoe-het-werkt" element={<Hoehetwerkt />} />
      <Route path="/privacy-verklaring" element={<Privacy />} />
      <Route path="/algemene-voorwaarden" element={<Voorwaarden />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contact/verzonden" element={<ContactSent />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/login" element={<Login />} />
      <Route path="/wachtwoordvergeten" element={<ForgotPassword />} />
      <Route path="/wachtwoordvergeten/mail" element={<ForgotPasswordMail />} />
      <Route path="/:id/wachtwoordvergeten/:token" element={<ForgotPasswordChange />} />
      <Route path="/" element={<ForgotPasswordChange />} />
      <Route path="/link-ongeldig" element={<LinkExpired />} />
      <Route path="/register" element={<Register />} />
      <Route path="/aanmelden" element={<Activate />} />
      <Route path="/account/aangemeld" element={<AccountActivated />} />
      <Route path="/welkom-bij-vonnisbank" element={<RegisterDone />} />
      <Route path="/account" element={<Account />} />
      <Route path="/account/edit" element={<AccountEdit />} />
      <Route path="/Colleagues" element={<Colleagues />} />
      <Route path="/vonnis/mijn" element={<VonnisMine />} />
      <Route path="/vonnis/plaatsen" element={<VonnisCreate />} />
      <Route path="/vonnis/edit/:vonnisid" element={<VonnisEdit />} />
      <Route path="/vonnis/zoeken" element={<VonnisSearch />} />
      <Route path="/vonnis/aangemaakt" element={<VonnisCreated />} />
      <Route path="/herincasso/plaatsen" element={<HerIncasso />} />
      <Route path="/herincasso/aangemaakt" element={<HerincassoCreated />} />
      <Route path="/incasso/plaatsen" element={<Incasso />} />
      <Route path="/incasso/aangemaakt" element={<IncassoCreated />} />
      <Route path="/pre-vonnisbank/plaatsen" element={<Factuur />} />
      <Route path="/pre-vonnisbank/aangemaakt" element={<FactuurCreated />} />
      <Route path="/pre-vonnisbank/mijn" element={<PrevonnisbankMine />} />
      <Route path="/pre-vonnisbank/voorwaarden" element={<PrevonnisbankVoorwaarden />} />
      <Route path="/pre-vonnisbank/uitleg" element={<PrevonnisbankUitleg />} />
      <Route path="/bedrijfsrapport/opvragen" element={<Rapport />} />
      <Route path="/bedrijfsrapport/opgevraagd" element={<RapportCreated />} />
      <Route path="/gratis/gebruikt" element={<FreeCta />} />
      <Route path="/blondedcms/admin" element={<Blondedcms />} />
      <Route path="/blondedcms/gebruikers" element={<BlondedcmsUsers />} />
      <Route path="/blondedcms/vonnissen" element={<BlondedcmsVonnis />} />
      <Route path="/blondedcms/herincasso" element={<BlondedcmsHerincasso />} />
      <Route path="/blondedcms/incasso" element={<BlondedcmsIncasso />} />
      <Route path="/blondedcms/bedrijfsrapporten" element={<BlondedcmsRapport />} />
      <Route path="/blondedcms/prevonnisbank" element={<BlondedcmsPrevonnisbank />} />
      <Route path="/blondedcms/bedrijven" element={<BlondedcmsCompanies />} />
      <Route path="/blondedcms/tools" element={<BlondedcmsTools />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
