import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import '../../styles/App.scss';
import '../../styles/backend/vonnisPages.scss'

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

class MinePrevonnisbankList extends React.Component {

    state = {
        vonnis: [],
        cocNumber: '',
        token: {},
        jwt: ''
    }

    componentDidMount() {
        setTimeout(() => {

            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({jwt: response.data.token});

                    this.setState({token: user}, () => {
                        this.getVonnisList();
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getVonnisList() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/prevonnisbank/mine/` + this.state.token.id,  {
            withCredentials: true,
        })
        .then(response => { 
            const vonnisMine = response.data.vonnisMine;
            this.setState({ vonnis: vonnisMine }, () => {

                //HIDE TABLE AND DISPLAY ERROR IF NONE
                var table = document.querySelector('.vonnis-table');
                var alert = document.querySelector('.alert');

                if (this.state.vonnis.length === 0) {
                    alert.classList.remove('hidden');
                    table.classList.add('hidden');
                } else {
                    alert.classList.add('hidden');
                    table.classList.remove('hidden');
                }
            });
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    toggleVonnis(e) {
        var toggleElement = e.target;

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        var closestVonnis = e.target.closest('.vonnis').getAttribute('id');

        if (toggleElement.classList.contains('status-open')) {
            axios.post(`${baseUrl}/api/prevonnisbank/toggle/${closestVonnis}/${this.state.token.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${this.state.jwt}`
                },
                withCredentials: true,
            })
            .then(response => { 
                toggleElement.classList.add('status-closed');
                toggleElement.classList.remove('status-open');
                toggleElement.setAttribute('data-tooltip-content', 'Indien een hoger beroep of een cassatie gewonnen is door de schuldeiser, kunt u hiermee het vonnis weer zichtbaar maken in de Zoekfunctie.');
            })
            .catch(error => {
                console.log(error);
            });
        } else {
            axios.post(`${baseUrl}/api/prevonnisbank/toggle/${closestVonnis}/${this.state.token.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${this.state.jwt}`
                },
                withCredentials: true,
            })
            .then(response => {
                toggleElement.classList.add('status-open');
                toggleElement.classList.remove('status-closed');
                toggleElement.setAttribute('data-tooltip-content', 'Als een schuldenaar in hoger beroep of cassatie gaat, dan kunt u hiermee het vonnis onzichtbaar maken in de Zoekfunctie.');
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    buildVonnisList(vonnisData) {
        if (vonnisData.length > 0) {
            return vonnisData.map((vonnisData, index) => (
                <tr key={index} className="vonnis visible" id={vonnisData._id}>
                    <td className="coc-selector">{vonnisData.cocNumber}</td>
                    <td className="text-right">€ {vonnisData.totalAmount}</td>
                    <td>
                        {vonnisData.paid ? <div className="status-toggle status-open" onClick={this.toggleVonnis.bind(this)}></div> : <div className="status-toggle status-closed" onClick={this.toggleVonnis.bind(this)}></div>}
                    </td>
                </tr>
            ));
        }
    }

    updateVonnisList(e) {
        e.preventDefault();

        var cocNumberNodes = document.querySelectorAll('.coc-selector');

        for (var i = 0; i < cocNumberNodes.length; i++) {
            var cocNumber = cocNumberNodes[i].innerHTML;
            
            if (!cocNumber.includes(this.state.cocNumber)) {
                cocNumberNodes[i].closest(".vonnis").classList.remove('visible');
                cocNumberNodes[i].closest(".vonnis").classList.add('hidden');
            } else {
                cocNumberNodes[i].closest(".vonnis").classList.add('visible');
                cocNumberNodes[i].closest(".vonnis").classList.remove('hidden');
            }
        }
        
        //DISPLAY ERROR IF NONE
        var alert = document.querySelector('.alert');
        var table = document.querySelector('.vonnis-table');

        var vonnisVisible = document.querySelectorAll('.vonnis.visible');

        if (vonnisVisible.length === 0) {
            alert.classList.remove('hidden');
            table.classList.add('hidden');
        } else {
            alert.classList.add('hidden');
            table.classList.remove('hidden');
        }
    }

    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="main-container grey-intro">
                    <div className="page-intro with-searchbox">
                        <div className="container mx-auto">
                            <h1>Mijn vonnissen</h1>
                            <div className="intro">
                                <p>Hieronder staan uw ingevoerde vonnissen</p>
                            </div>
                        </div>
                    </div>
                 </div>
                 <div className="container mx-auto">
                    <div className="flex justify-center">
                        <form onSubmit={this.updateVonnisList.bind(this)} className="searchbox-form">
                            <div className='grid grid-cols-12'>
                                <div className="form-group col-span-10">
                                    <input value={this.state.cocNumber} onChange={(e) => this.setState({cocNumber: e.target.value})} type="number" placeholder="Zoek op gedeelte KvK-nummer" className="searchbox-input form-control"/>
                                </div>
                                <div className="form-group col-auto">
                                    <button className="btn btn-default searchbox-button" type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                                </div>
                            </div>
                        </form>
                    </div>
                 </div>
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="table-responsive vonnis-table hidden">
                            <table className="data-table">
                                <thead>
                                    <tr>
                                        <th>KvK-nummer</th>
                                        <th className="text-right">Totaalbedrag</th>
                                        <th>Betaald</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.buildVonnisList(this.state.vonnis)}
                                </tbody>
                            </table>
                        </div>

                        <div className="alert alert-warning hidden" role="alert">Geen vonnissen gevonden.</div>
                        
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <MinePrevonnisbankList navigate={navigate} />
}

export default WithNavigate;