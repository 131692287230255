import { Header, Footer } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

function App() {
    return (
        <div className="Main">
             <Header />
             <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Voorwaarden pre-vonnisbank</h1>
                        </div>
                        
                        <p>
                            <strong>Voorwaarden van het traject zijn:</strong>
                        </p>

                        <p>
                            De aangeleverde documenten zijn volledig en zijn waarheidsgetrouw overgedragen aan Vonnisbank.nl
                            De incassowerkzaamheden worden uitgevoerd op basis van no cure no pay, dat betekent is Vonnisbank.nl niet succesvol, dan wordt er ook niets in rekening gebracht bij opdrachtgever.
                        </p>

                        <p>
                            De schuldenaar betaalt de incassokosten, deze komen ten goede aan Vonnisbank.nl
                            Bij deelbetalingen worden deze als eerste afgerekend met Vonnisbank.nl. (Is wettelijk bepaald)
                        </p>

                        <p>
                            Wordt het incassotraject op verzoek opdrachtgever stopgezet, dan wordt 50% van de incassokosten bij opdrachtgever in rekening gebracht.
                        </p>

                        <p>
                            Bij succesvol incasseren, rekent Vonnisbank.nl 10% succes-fee over het geïncasseerde bedrag.
                        </p>

                        <p>
                            Indien een gerechtelijk incassotraject opgestart moet worden, gaat dat altijd in overleg en in opdracht van de opdrachtgever.
                            Kosten betekenen dagvaarding en griffierecht dienen vooraf betaald te worden.
                        </p>

                        <p>
                            Het opstellen van een dagvaarding met producties worden uitgevoerd tegen betaling ad € 175,00 excl btw. ( deze kosten zijn niet verhaalbaar op de schuldenaar )
                        </p>

                        <p>
                            Bij verweer en rechtbank bezoek rekent Vonnisbank.nl € 125,00 per uur excl btw.
                        </p>

                        <p>
                            Succesvol is Vonnisbank.nl ook bij het behalen van een vonnis, dan wordt 50% succes-fee in rekening gebracht, restant succes-fee bij sluiting van het dossier.
                        </p>

                        <p>
                            Vonnissen worden geregistreerd bij Vonnisbank.nl
                        </p>
                    </div>
                </div>
             <Footer />
        </div>
    );
}

export default App;