import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import '../../../styles/App.scss';
import '../../../styles/backend/blondedcms.scss';

class Blondedcms extends React.Component {

    state = {
        //GET ACCOUNT INFO
        user: {},

        //GET VONNISBANK INFO
        userCount: 0,
        vonnisCount: 0,
        herincassoCount: 0,
        incassoCount: 0,
        rapportCount: 0,
        companyCount: 0,
        prevonnisbankCount: 0
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user || user.role !== 'vonnisbank_admin') {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({ user: user });
                    this.getUserCount();
                    this.getVonnisCount();
                    this.getHerincassoCount();
                    this.getIncassoCount();
                    this.getRapportCount();
                    this.getCompanyCount();
                    this.getPrevonnisbankCount();
                }
            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getUserCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/users`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ userCount: response.data.users.length });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getVonnisCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/vonnis`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ vonnisCount: response.data.vonnis.length });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getHerincassoCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/herincasso`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ herincassoCount: response.data.herincasso.length });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getIncassoCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/incasso`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ incassoCount: response.data.incasso.length });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getRapportCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/bedrijfsrapport`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ rapportCount: response.data.bedrijfsrapport.length });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getPrevonnisbankCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/prevonnisbank`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ prevonnisbankCount: response.data.prevonnisbank.length });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getCompanyCount() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
    
        axios.get(`${baseUrl}/api/admin/companies`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ companyCount: response.data.companies.length });
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    render() {
        return (
            <div className="Main">
                 <div className="blondedcms">
                    <div className="flex flex-col items-center w-16 overflow-hidden blonded-sidebar">
                        <div className="flex items-center justify-center">
                            <svg className="sidebar-icon w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                            </svg>
                        </div>
                        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
                            <button className="flex items-center justify-center w-12 h-12 mt-2 rounded bg-gray-900 text-white">
                                <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                            </button>
                        </div>
                        
                        <a className="flex items-center justify-center w-16 h-16 mt-auto hover:bg-gray-900 hover:text-white" href="/account">
                            <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </a>
                    </div>
                    <div className="blonded-content">
                        <div className="cms-wrapper">
                            <div className="cms-header">
                                <h1 className="cms-title">Blonded CMS</h1>
                                <p className="cms-subtitle">Welkom {this.state.user.name}</p>
                            </div>
                            <div className="cms-body">
                                <h2 className="body-title">Vonnisbank samenvatting en status</h2>
                                <p className="body-subtitle">Algemene informatie over de Vonnisbank applicatie.</p>

                                <div className="flex xl:space-x-8 lg:space-x-6 my-6">
                                    <div className="basis-1/4">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-users" onClick={this.getUserCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Gebruikers</h3>
                                                        <p className="box-content-status">{this.state.userCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle gebruikers, schuldeisers en schuldenaren.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/gebruikers">Bekijk gebruikers</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basis-1/4">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-vonnis" onClick={this.getVonnisCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Vonnissen</h3>
                                                        <p className="box-content-status">{this.state.vonnisCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle vonnissen, openstaande en gesloten.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/vonnissen">Bekijk vonnissen</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basis-1/4">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-status" onClick={this.getCompanyCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Bedrijven</h3>
                                                        <p className="box-content-companies">{this.state.companyCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle geregistreerde bedrijven.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/bedrijven">Bekijk bedrijven</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basis-1/4">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-status" onClick={this.getHerincassoCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Herincassos</h3>
                                                        <p className="box-content-companies">{this.state.herincassoCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle geregistreerde herincassos.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/herincasso">Bekijk herincassos</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex xl:space-x-8 lg:space-x-6 my-6">
                                    <div className="basis-1/3">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-users" onClick={this.getIncassoCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Incassos</h3>
                                                        <p className="box-content-status">{this.state.incassoCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle geplaatste incassos.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/incasso">Bekijk gebruikers</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basis-1/3">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-vonnis" onClick={this.getRapportCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Bedrijfsrapporten</h3>
                                                        <p className="box-content-status">{this.state.rapportCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle opgevraagde bedrijfsrapporten.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/bedrijfsrapporten">Bekijk bedrijfsrapporten</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basis-1/3">
                                        <div className="box">
                                            <div className="box-content">
                                                <div className="reload-icon reload-vonnis" onClick={this.getPrevonnisbankCount.bind(this)}>
                                                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                </div>
                                                <div className="flex">
                                                    <div className="box-content-icon">
                                                        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                                        </svg>
                                                    </div>
                                                    <div className="box-content-text self-center">
                                                        <h3 className="box-content-title">Pre-vonnisbank</h3>
                                                        <p className="box-content-status">{this.state.prevonnisbankCount}</p>
                                                    </div>
                                                </div>
                                                <p className="box-content-subtitle">Alle Pre-vonnisbank items.</p>
                                            </div>
                                            <div className="box-footer">
                                                <div className="flex justify-end">
                                                    <a className="box-footer-link" href="/blondedcms/prevonnisbank">Bekijk Pre-vonnisbank</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <Blondedcms navigate={navigate} />
}

export default WithNavigate;