import { Header, Footer } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

function App() {
    return (
        <div className="Main">
             <Header />
             <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Uitleg pre-vonnisbank</h1>
                        </div>
                        
                        <p>
                            Vonnisbank.nl heeft als doel om de onnodige incassokosten in Nederland uit te bannen.
                        </p>

                        <p>
                            Is er al een vonnis op een schuldenaar / debiteur dan heeft het meestal geen zin om aan te sluiten in de rij van schuldeisers.
                            Dit kost de ondernemer naast zijn onbetaald factuur/facturen ook nog eens extra incassokosten en gerechtelijke proceskosten.
                        </p>

                        <p>
                            Daarom heeft vonnisbank.nl voor haar leden een gratis registratie en pre incassotraject in het leven geroepen.
                        </p>

                        <p>
                            Openstaande vorderingen kunnen geregistreerd worden, voordat er überhaupt een vonnis gehaald.
                            Puur en alleen om een incassotraject of zelfs gerechtelijk traject te voorkomen.
                        </p>

                        <p>
                            Klanten van Vonnisbank.nl kunnen dus hun vordering registreren bij Pre-Vonnisbank.nl
                        </p>

                        <p>
                            Dan krijgt de debiteur / schuldenaar een mail en sms bericht dat hij geregistreerd staat bij Pre-Vonnisbank.nl en wordt verzocht om te betalen aan de schuldeiser.
                            Na 30 dagen neemt Vonnisbank.nl met u als schuldeiser contact op, om te vragen of het klopt dat de vordering nog openstaat en of u wil dat Vonnisbank.nl uw debiteur een WIK-brief stuurt.
                            Deze wik brief is wettelijk verplicht, wil men aanspraak maken op de wettelijke incassokosten.
                            Hiervoor rekent Vonnisbank.nl € 15,00 excl. btw.
                        </p>

                        <p>
                            Mocht na 15 dagen blijken dat uw debiteur nog niet is overgegaan tot betalen, dan kunt u Vonnisbank.nl opdracht geven om een incasso traject op te starten.
                            Kosten voor het minnelijk- en gerechtelijk incassokosten kunt u vinden onder het kopje FAQ.
                        </p>
                    </div>
                </div>
             <Footer />
        </div>
    );
}

export default App;